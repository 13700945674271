import axios from 'axios'
//创建axios的一个实例
// http://28e55o6211.qicp.vip/
console.log('🚀process.env.VUE_APP_BASE_API👉👉', process.env.VUE_APP_BASE_API)
import Vue from 'vue';
const msg = new Vue;

var instance = axios.create({
    // baseURL: 'https://unify-system.iiguan.com', //接口统一域名 正式
    baseURL: 'https://test-hkb-jigou.diaoeng.cn/inst', //接口统一域名 测试
    timeout: 9000 //设置超时
})
instance.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
    config.headers['authorization'] = sessionStorage.getItem("token")
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
    if (response.data.code !== 200) {
        msg.$message({
            message: response.data.msg,
            type: 'error'
        })
    }
    if (response.headers['content-length'] == 0) {
        localStorage.clear();
        sessionStorage.clear();
        location.reload();
    }
    if (response.data.code === 30010) {
        localStorage.clear();
        sessionStorage.clear();
        location.reload();
    }
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    if (error.response.data.msg === '未登录') {
        localStorage.clear();
        sessionStorage.clear();
        location.reload();
    }
    return Promise.reject(error.response);
});


export default instance