import request from './request.js';

export function user_login({
    code,
    phone
}) {
    return request({
        url: '/login/doLogin',
        method: 'post',
        data: {
            code,
            phone
        }
    })
}

//获取验证码
export function post_login({
    phone
}) {
    return request({
        url: '/login/getCode',
        method: 'GET',
        params: {
            phone
        }
    })
}
//登出操作
export function log_out({
    token
}) {
    return request({
        url: '/system/log-out',
        method: 'GET'
    })
}
//查看当前用户信息
export function find_user() {
    return request({
        url: '/system/find-user',
        method: 'GET'
    })
}